// stylelint-disable declaration-no-important

//
// Visibility utilities
//

.axu-visible {
  visibility: visible;
}

.axu-invisible {
  visibility: hidden;
}

// stylelint-disable property-blacklist, declaration-no-important

//
// Border
//

.axu-border {
  border: $border-width solid $border-color;
}

.axu-border-top {
  border-top: $border-width solid $border-color;
}

.axu-border-right {
  border-right: $border-width solid $border-color;
}

.axu-border-bottom {
  border-bottom: $border-width solid $border-color;
}

.axu-border-left {
  border-left: $border-width solid $border-color;
}

.axu-border-0 {
  border: 0;
}

.axu-border-top-0 {
  border-top: 0;
}

.axu-border-right-0 {
  border-right: 0;
}

.axu-border-bottom-0 {
  border-bottom: 0;
}

.axu-border-left-0 {
  border-left: 0;
}

@each $color, $value in $theme-colors {
  .axu-border-#{$color} {
    border-color: $value;
  }
}

.axu-border-white {
  border-color: $white;
}

//
// Border-radius
//

.axu-rounded-sm {
  border-radius: $border-radius-sm;
}

.axu-rounded {
  border-radius: $border-radius;
}

.axu-rounded-top {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.axu-rounded-right {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.axu-rounded-bottom {
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.axu-rounded-left {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.axu-rounded-lg {
  border-radius: $border-radius-lg;
}

.axu-rounded-circle {
  border-radius: 50%;
}

.axu-rounded-pill {
  border-radius: $rounded-pill;
}

.axu-rounded-0 {
  border-radius: 0;
}

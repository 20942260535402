// stylelint-disable declaration-no-important

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .axu-d#{$infix}-#{$value} {
        display: $value;
      }
    }
  }
}


//
// Utilities for toggling `display` in print
//

@media print {
  @each $value in $displays {
    .axu-d-print-#{$value} {
      display: $value;
    }
  }
}

.btn-outline-secondary {
  color: $btn-outline-secondary-color;
  background-color: $btn-outline-secondary-bg-color;
  border-color: $btn-outline-secondary-border-color;

  &:hover {
    color: $btn-outline-secondary-color;
    background-color: $btn-outline-secondary-bg-color-hover;
    border-color: $btn-outline-secondary-border-color-hover;
  }
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $btn-outline-secondary-color;
  border-color: $btn-outline-secondary-border-color;
}
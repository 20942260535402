// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .axu-#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length;
        }
        .axu-#{$abbrev}t#{$infix}-#{$size},
        .axu-#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length;
        }
        .axu-#{$abbrev}r#{$infix}-#{$size},
        .axu-#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length;
        }
        .axu-#{$abbrev}b#{$infix}-#{$size},
        .axu-#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length;
        }
        .axu-#{$abbrev}l#{$infix}-#{$size},
        .axu-#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length;
        }
      }
    }

    // Negative margins (e.axu-g.axu-, where `.axu-mb-n1` is negative version of `.axu-mb-1`)
    @each $size, $length in $spacers {
      @if $size != 0 {
        .axu-m#{$infix}-n#{$size} {
          margin: -$length;
        }
        .axu-mt#{$infix}-n#{$size},
        .axu-my#{$infix}-n#{$size} {
          margin-top: -$length;
        }
        .axu-mr#{$infix}-n#{$size},
        .axu-mx#{$infix}-n#{$size} {
          margin-right: -$length;
        }
        .axu-mb#{$infix}-n#{$size},
        .axu-my#{$infix}-n#{$size} {
          margin-bottom: -$length;
        }
        .axu-ml#{$infix}-n#{$size},
        .axu-mx#{$infix}-n#{$size} {
          margin-left: -$length;
        }
      }
    }

    // Some special margin utils
    .axu-m#{$infix}-auto {
      margin: auto;
    }
    .axu-mt#{$infix}-auto,
    .axu-my#{$infix}-auto {
      margin-top: auto;
    }
    .axu-mr#{$infix}-auto,
    .axu-mx#{$infix}-auto {
      margin-right: auto;
    }
    .axu-mb#{$infix}-auto,
    .axu-my#{$infix}-auto {
      margin-bottom: auto;
    }
    .axu-ml#{$infix}-auto,
    .axu-mx#{$infix}-auto {
      margin-left: auto;
    }
  }
}

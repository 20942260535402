// stylelint-disable declaration-no-important

.axu-align-baseline {
  vertical-align: baseline;
}

// Browser default
.axu-align-top {
  vertical-align: top;
}

.axu-align-middle {
  vertical-align: middle;
}

.axu-align-bottom {
  vertical-align: bottom;
}

.axu-align-text-bottom {
  vertical-align: text-bottom;
}

.axu-align-text-top {
  vertical-align: text-top;
}

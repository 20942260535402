/**
 * Contains the general Bootstrap theme css, to be applied before module extension CSS.
 */
@import "my-izen/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "ax-bs-utilities/ax-utilities";

// Headings
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: -0.5px;
  margin-left: 0;
  margin-right: 0;
}

// Alerts
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant($across-alert-bg, theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
  }
}

// Tables

.table {
  margin-bottom: $spacer * 1.25;
}

// Table within card
.card-body .table:first-child tr th {
  border-top: 0;
  margin-top: -.5rem;
}

.card-body .table:last-child {
  margin-bottom: 0;
}

// Dropdowns
.dropdown-menu {
  padding: 0;

  .dropdown-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-left: 5px solid transparent;
  }
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item.active {
  background-color: $dark-focused;
  color: $white;
  border-color: $primary;
}

.dropdown-menu.show {
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}


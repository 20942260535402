@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/functions";
/* Bootstrap variables */

/*
  Note: When overriding bootstrap variables, !default should be removed to apply the new variable everywhere.
  If a variable defines !default, it can possibly be re-assigned by bootstrap defaults.
  https://getbootstrap.com/docs/4.2/getting-started/theming/#variable-defaults
 */
$spacer: 1rem !default;
$line-height-base: 1.5 !default;

// Colors
$gray-100: #efefef !default;
$gray-200: #eaeaea !default;
$gray-300: #e9e9e9 !default;
$gray-400: #ced4da !default;
$gray-700: #555 !default;

$blue: lighten(#002c41, 20%) !default;
$red: #dd000c !default;
$green: #27a327 !default;
$dark: #222d32 !default;
$white: #fff !default;

$success: #27a327 !default;
$warning: #FF9203 !default;
$danger: #dd000c !default;
$info: #42abdc !default;

$primary: #29B58F !default; // #599926 !default;
$dark-focused: darken($dark, 15%) !default;
$primary-focused: darken($primary, 15%) !default;


// Options
$enable-rounded: false !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: false !default;

// Font
@import url(https://fonts.googleapis.com/css?family=Oxygen:300,400,700);

$font-family-sans-serif: 'Oxygen', sans-serif !default;
$font-size-base: 0.875rem !default;
$font-weight-base: normal !default;

// Body
$body-bg: #f4f4f4 !default;

// Headings
$headings-margin-bottom: $spacer * 0.75 !default;
$headings-font-weight: normal !default;
$headings-line-height: 1.1 !default;
$headings-color: $dark !default;

$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 1.85 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1 !default;
$h6-font-size: $font-size-base * 0.85 !default;

// Alerts
$alert-padding-y: 1rem !default;
$alert-padding-x: 1rem !default;
$alert-border-level: 1 !default;
$alert-color-level: 2 !default;

// Navbar
$navbar-brand-font-size: $font-size-base !default;
$navbar-padding-y: 0 !default;
$navbar-padding-x: $spacer !default;
$nav-link-padding-y: 0.875rem !default;
$navbar-nav-link-padding-x: 0.7rem !default;

$navbar-dark-color: #eee !default;
$navbar-dark-hover-color: #eee !default;
$navbar-dark-active-color: #eee !default;

// Breadcrumb
$breadcrumb-bg: $gray-100 !default;
$breadcrumb-padding-y: 0.5rem !default;

// Dropdown
$dropdown-link-active-bg: $dark !default;
$dropdown-link-active-color: $white !default;
$dropdown-link-hover-bg: $dark-focused !default;
$dropdown-link-hover-color: $white !default;

// Tabs
$nav-tabs-link-active-bg: white !default;
$nav-tabs-link-active-border-color: none !default;

// Card
$card-spacer-x: 1rem !default;
$card-spacer-y: 0.7rem !default;

// List group
$list-group-item-padding-x: 1rem !default;
$list-group-item-padding-y: 0.7rem !default;
$list-group-border-color: $gray-100 !default;
$list-group-action-color: $primary !default;//#0071a7 !default;
$list-group-hover-bg: $dark-focused !default;//#eee !default;
$list-group-action-hover-color: darken($list-group-action-color, 20%) !default;
$list-group-active-color: $white !default;//$gray-400 !default;
$list-group-active-bg: $dark-focused !default;

// Button
$btn-padding-x: 2rem !default;
$btn-padding-y: 0.375rem !default;
$btn-padding-x-lg: 2rem !default;
$btn-padding-y-lg: 0.375rem !default;
$btn-padding-x-sm: 1rem !default;
$btn-padding-y-sm: 0.375rem !default;

// Table
$table-cell-padding: 0.5rem !default;


// imports leftover bootstrap variables.
@import "../../../../../node_modules/bootstrap/scss/variables";
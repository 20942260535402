// Form Controls
.form-control {
  color: $gray-700;
  border: 1px solid $gray-300;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control-static {
  margin-bottom: 0.5rem;
}

// Select controls
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  min-height: 1.25rem;
}

.form-control.bootstrap-select {
  .btn.dropdown-toggle {
    color: $gray-700;
    border: 1px solid $gray-300;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-color: $white;
    padding: 0.375rem 0.75rem;

    &:after {
      color: $gray-550;
    }

    &.btn-light {
      color: initial;
    }
  }
}

// Labels
.form-group label {
  //display: inline-block;
  max-width: 100%;
  //margin-bottom: 5px;
  font-weight: bold;
  white-space: nowrap;

  > sup.required {
    margin-left: 3px;
  }
}

.custom-control-label {
  white-space: nowrap !important;
}

.custom-checkbox, .custom-radio {
  label {
    font-weight: normal;
  }
}


// Input group icons
.input-group-append > .input-group-text {
  color: $gray-700;
  background-color: $gray-100;
  border: 1px solid $gray-300;
}

// stylelint-disable declaration-no-important

// Width and height
@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .axu-#{$abbrev}-#{$size} {
      #{$prop}: $length;
    }
  }
}

.axu-mw-100 {
  max-width: 100%;
}

.axu-mh-100 {
  max-height: 100%;
}

// Viewport additional helpers

.axu-min-vw-100 {
  min-width: 100vw;
}

.axu-min-vh-100 {
  min-height: 100vh;
}

.axu-vw-100 {
  width: 100vw;
}

.axu-vh-100 {
  height: 100vh;
}

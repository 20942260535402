/**
 * Contains the admin web specific theme css, to be applied after module extension CSS.
 */
@import "my-izen/variables";

//
//* {
//  // this reduces the boldness of the font
//  -webkit-font-smoothing: antialiased;
//}

body {
  padding-top: 5rem;
}

.page-header {
  margin: 2rem 0.25rem 1.25rem 0.25rem;
  border-bottom: 1px solid $gray-500;
  padding-bottom: 0.5rem;
}

h2, h3 {
  &.page-header {
    border-bottom: 1px dotted $gray-400;
  }
}

// List group
.list-group-subgroup .list-group-item {
  border: 0;
}

.list-group-flush .list-group-item {
  border-left: 5px solid transparent;
}

.list-group-item {
  &:hover, &:focus {
    border-left-color: $primary;
    color: $white;
  }

  &.active {
    border-left-color: $primary;
  }
}

.list-group-subgroup-toggle {
  &:after {
    color: $gray-500 !important;
  }
}

.card-header + .list-group > .list-group-subgroup > .list-group-item:first-child {
  border-color: $gray-100;
}

// Developer tools
.nav-panels .admin-nav-developer-tools .card-header {
  color: $developer-tools-heading-color;
  background-color: $developer-tools-heading-background-color;
  border-color: $developer-tools-heading-border-color;
}


// Login form
.form-signin .page-header {
  background-size: 60px;
  padding-top: 80px;
}

// Navbar - main navigation
.navbar-header .navbar-brand {
  background: unset !important;
  padding-left: 10px !important;
  color: $primary
}

.nav-link {
  font-weight: 300;
}

.navbar {
  .dropdown-menu {
    margin-top: 0;
    border-top: 0;

    // expand dropdown to left if not enough room
    right: 0;
    left: auto;
  }

  .navbar-dropdown-align-left > .dropdown-menu {
    left: 0;
    right: auto;
  }
}

.navbar-dark {
  .navbar-nav {
    .nav-link {
      border-bottom: 5px solid $dark;
      @include hover-focus {
        background-color: $dark-focused;
        border-bottom-color: $primary;
        color: $white
      }
    }
  }
}

// Sidebar
.sidebar {
  padding: 0;

  .card-header {
    font-size: 1rem;
    line-height: 1.1;
    letter-spacing: -0.5px;
  }
}

@import "classic/listView";
@import "classic/controls";
@import "classic/fieldset";
@import "classic/buttons";
@import "classic/formView";
@import "classic/embedded-collection";
@import "my-izen/navbar";
@import "classic/text";
@import "classic/codemirror";

.navbar-header .navbar-brand {
  font-weight: 600;
}

.navbar-dark .navbar-brand {
  &:hover,&:focus {
    color: $primary;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $gray-400;

  &.active {
    color: $white;
    background-color: $dark-focused;
    border-color: $primary;
  }
}

.element-fieldset.element-fieldset-fieldset {
  border: solid 1px $gray-300;
  padding: 0.75rem;
  margin-bottom: 1rem;

  .element-fieldset-title {
    border: 0;
    padding: 0 0.75rem;
    font-size: 0.85rem;
    border-bottom: none;
    color: $gray-550;
    width: auto;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.element-fieldset {
  .element-fieldset-title.card-header {
    color: $white;
  }

  .element-fieldset-footer {
    background-color: $gray-100;
    border-top: 1px solid $gray-350;
    color: $blue;
  }
}